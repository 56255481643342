import React from "react";
import "bulma/css/bulma.css";

import Layout from "../components/layout";
import "../styles/fontFaces.css";

export default () => (
  <Layout pinktag blackColor whiteLogo whiteNav className="blackBackground">
    <div className="videoSection">
      <iframe
        title="intro"
        className="thatIframe"
        src="https://www.youtube.com/embed/1oy9zJykKvo?autoplay=1&loop=1&playlist=1oy9zJykKvo"
        frameborder="0"
        allow="autoplay"
        allowfullscreen
      ></iframe>
    </div>
  </Layout>
);
